import { useFetchers } from '@remix-run/react'
import { THEME, USER_PREFERENCES, useUserPreferences } from '~/modules/settings'

export function useTheme() {
  const { userPreferences } = useUserPreferences()
  const fetchers = useFetchers()
  const themeFetcher = fetchers.find(
    (fetcher) => fetcher.formData?.get('preferenceName') === USER_PREFERENCES.Theme,
  )
  const optimisticTheme = themeFetcher?.formData?.get('preferenceOption')
  const theme = optimisticTheme ?? userPreferences?.theme ?? THEME.Dark

  return { theme, isDark: theme === THEME.Dark }
}
